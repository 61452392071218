/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useRef, useCallback } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";

import {
  addPlayer,
  resetPlayers,
  resetScores
} from "../store/modules/players/actions";
import Player from "./Player";
import { Player as IPlayer } from "../store/modules/players/types";

export default function() {
  const dispatch = useDispatch();
  const players: IPlayer[] = useSelector((state: any) => state.players);

  const [isEditing, setEditing] = useState<boolean>(false);
  const [inputState, setInput] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);

  const handleResetPlayers = useCallback(() => dispatch(resetPlayers()), [
    dispatch
  ]);
  const handleResetScores = useCallback(() => dispatch(resetScores()), [
    dispatch
  ]);

  useEffect(() => {
    if (isEditing && inputRef.current) {
      inputRef.current.focus();
    }
  }, [isEditing]);

  const createNewPlayer = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(addPlayer(inputState));
    setEditing(false);
    setInput("");
  };

  let content = null;
  if (isEditing) {
    content = (
      <form onSubmit={createNewPlayer}>
        <NameInput
          ref={inputRef}
          size={10}
          value={inputState}
          onChange={e => setInput(e.target.value)}
          onClick={e => e.stopPropagation()}
          onBlur={() => {
            setEditing(false);
            setInput("");
          }}
        />
      </form>
    );
  }
  return (
    <Container>
      <List>
        {Array.from(Object.values(players)).map(player => (
          <Player key={player.name} player={player} />
        ))}
        <AddPlayer role="button" onClick={() => setEditing(!isEditing)}>
          {!isEditing && "Add Player ＋"}
          {content}
        </AddPlayer>
        {/* <Player placeholder /> */}
      </List>
      <Action onClick={handleResetPlayers}>Reset Players</Action>
      <Action onClick={handleResetScores}>Reset Scores</Action>
    </Container>
  );
}

const Container = styled.div`
  width: 100%;
`;
const List = styled.ul`
  display: flex;
  flex-wrap: wrap;
  padding: 0;
`;
const AddPlayer = styled.div`
  background: ${props => props.theme.colors.buttonColor};
  opacity: 0.5;
  margin: 8px;
  flex: 1 0 120px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-height: 64px;
  border-radius: 2px;
`;

const NameInput = styled.input`
  color: white;
  transition: background 200ms ease-in-out;
  background: transparent;
  outline: none;
  &:focus {
    background: rgba(0, 0, 0, 0.5);
    outline: none;
    border: none;
  }
`;
const Action = styled.a`
  display: inline-block;
  padding: 4px;
  border: 1px solid white;
  margin: 4px;
  letter-spacing: 3px;
  border-radius: 2px;

  &:hover {
    font-weight: bold;
  }
`;
