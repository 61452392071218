import applicationSagas from "./application/sagas";
import players from "./players/reducer";
import { PlayerState } from "./players/types";

export const reducers = {
  players
};

export const sagas = [applicationSagas];

export interface AppState {
  players: PlayerState;
}
