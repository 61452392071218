import { Reducer } from "redux";
import produce from "immer";

import { PlayerState, PlayerActions } from "./types";

import { HYDRATE } from "../application/actions";
import {
  ADD_PLAYER,
  INCREASE_SCORE,
  REMOVE_PLAYER,
  RESET_PLAYERS,
  RESET_SCORES
} from "./actions";
import { HydrateAction } from "../application/types";

const InitialState: PlayerState = {};

const reducer: Reducer<PlayerState, PlayerActions | HydrateAction> = (
  state = InitialState,
  action
) => {
  return produce(state, draft => {
    switch (action.type) {
      case RESET_PLAYERS: {
        return InitialState;
      }
      case RESET_SCORES: {
        Object.keys(draft).forEach(name => (draft[name].scores = []));
        return draft;
      }
      case HYDRATE: {
        return action.payload.players || InitialState;
      }
      case ADD_PLAYER: {
        const { name } = action.payload;
        draft[name] = { name, scores: [] };
        return;
      }
      case INCREASE_SCORE: {
        const { name, score } = action.payload;
        draft[name].scores.push(score);
        return;
      }
      case REMOVE_PLAYER: {
        const { name } = action.payload;
        delete draft[name];
        return;
      }
      default:
        return state;
    }
  });
};

export default reducer;
