import { setData, getData } from "../../../interfaces/persistance";

export function saveAll(data: any) {
  try {
    setData(data);
    return true;
  } catch (e) {
    console.error("couldn't persist", e);
  }

  return false;
}

export function loadAll() {
  try {
    const data = getData();

    return data;
  } catch (e) {
    console.error("couldn't persist", e);
  }

  return {};
}
