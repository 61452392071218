// Needed for redux devtools warning
/* eslint no-underscore-dangle: 0 */
import { createStore, compose, applyMiddleware, combineReducers } from "redux";
import { routerMiddleware as createRouterMiddleware } from "react-router-redux";
import { createBrowserHistory } from "history";
import createSagaMiddleware from "redux-saga";
import { fork, all } from "redux-saga/effects";
import { reducers, sagas } from "./modules";

const history = createBrowserHistory();
const enableDevTools =
  process.env.NODE_ENV !== "production" && window.__REDUX_DEVTOOLS_EXTENSION__;

// Options for the redux devtools can be specified here. If you do specify
// See http://extension.remotedev.io/docs/API/Arguments.html for options
const reduxDevToolsOptions = {
  // NOTE: Do not commit any custom options
  // actionsWhitelist: ['openOffers/']
};

if (Object.keys(reduxDevToolsOptions).length) {
  // eslint-disable-next-line no-console
  console.warn(
    "Custom redux devtool options have been specified.",
    reduxDevToolsOptions
  );
}

const routerMiddleware = createRouterMiddleware(history);
// TODO: pull initialState from store
const initialState = {
  players: {}
};

const reducer = combineReducers({ ...reducers });
const sagaMiddleware = createSagaMiddleware();
const enhancer = compose(
  applyMiddleware(routerMiddleware, sagaMiddleware),
  enableDevTools
    ? window.__REDUX_DEVTOOLS_EXTENSION__(reduxDevToolsOptions)
    : f => f
);
function* rootSaga() {
  yield all(sagas.map(saga => fork(saga)));
}

const store = createStore(reducer, initialState, enhancer);
window.store = store;
sagaMiddleware.run(rootSaga);
// initialData = user payload got back from /account API

export { history, store };
