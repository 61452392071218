import { AppState } from "../../store/modules";

export function getData(): AppState {
  const raw = localStorage.getItem("app-datastore") || "";
  const data = JSON.parse(raw) as AppState;

  return data;
}

export function setData(store: AppState) {
  if (!store) {
    return;
  }

  localStorage.setItem("app-datastore", JSON.stringify(store));
}
