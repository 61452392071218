import { takeLatest, fork, all, put, select, call } from "redux-saga/effects";
import * as api from "./api";

import { hydrate } from "./actions";

function* loadPrevious() {
  let store;
  try {
    store = yield call(api.loadAll);
  } catch (e) {
    console.warn("loading failed", e);
    store = false;
  }
  if (store) {
    yield put(hydrate(store));
  }
}

function* persistLatest() {
  console.log("persisting");
  const store = yield select(state => state);

  yield call(api.saveAll, store);
}

export default function* root() {
  yield all([fork(loadPrevious), takeLatest("*", persistLatest)]);
}
