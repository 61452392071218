import React, { useState } from "react";
import styled from "styled-components";
// import data from "scrabble-data/scrabble/dictionary.json";

const blockForm = (e: React.FormEvent) => e.preventDefault();

const getDictionary = async () => {
  const module = await import("scrabble-data/scrabble/dictionary.json");

  return module.default as string[];
};

enum DictionaryStatusEnum {
  UNLOADED,
  LOADING,
  READY,
  FAILED
}

export default function WordChecker() {
  const [isValid, setValid] = useState<boolean>(true);
  const [value, setValue] = useState<string>("");
  const [dictionaryStatus, setDictionaryStatus] = useState<
    DictionaryStatusEnum
  >(DictionaryStatusEnum.UNLOADED);
  const [dictionary, setDictionary] = useState<string[]>([]);

  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    if (dictionaryStatus === DictionaryStatusEnum.UNLOADED) {
      setDictionaryStatus(DictionaryStatusEnum.LOADING);
      try {
        const data = await getDictionary();
        setDictionary(data);
        setDictionaryStatus(DictionaryStatusEnum.READY);
      } catch (e) {
        setDictionaryStatus(DictionaryStatusEnum.FAILED);
      }
    }
    const valid = dictionary.indexOf(value.toLowerCase()) !== -1;

    setValid(valid);
    setValue(value);
  };

  return (
    <>
      <h2>Dictionary</h2>

      <Form onSubmit={blockForm}>
        <Input onChange={onChange} />
        <Validity valid={isValid}>{isValid ? "V" : "Inv"}alid</Validity>
      </Form>
      <ul>
        {value.length > 1 &&
          dictionary
            .filter(str => str.startsWith(value))
            .map(str => <ul>{str}</ul>)}
      </ul>
    </>
  );
}

const Form = styled.form`
  display: flex;
  width: 100%;
  justify-content: space-evenly;

  > * {
    flex: 1 1 120px;
    display: inline-block;
    margin: 8px;
  }
`;

const Input = styled.input``;
interface ValidityProps {
  valid: boolean;
}

const Validity = styled.span<ValidityProps>`
  display: inline-block;
  width: 50%;
  color: ${props => (props.valid ? "green" : "red")};
`;
