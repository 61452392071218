import {
  ResetPlayersAction,
  ResetScoresAction,
  AddPlayerAction,
  RemovePlayerAction,
  AddScoreAction
} from "./types";

export const ADD_PLAYER = "players/ADD_PLAYER";
export const REMOVE_PLAYER = "players/REMOVE_PLAYER";
export const INCREASE_SCORE = "players/INCREASE_SCORE";
export const RESET_PLAYERS = "players/RESET_PLAYERS";
export const RESET_SCORES = "players/RESET_SCORES";

export const resetPlayers = (): ResetPlayersAction => ({
  type: RESET_PLAYERS,
  payload: {}
});

export const resetScores = (): ResetScoresAction => ({
  type: RESET_SCORES,
  payload: {}
});

export const addPlayer = (name: string): AddPlayerAction => ({
  type: ADD_PLAYER,
  payload: { name }
});

export const removePlayer = (name: string): RemovePlayerAction => ({
  type: REMOVE_PLAYER,
  payload: { name }
});

export const addScore = (name: string, score: number): AddScoreAction => ({
  type: INCREASE_SCORE,
  payload: { name, score }
});
