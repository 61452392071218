import React, { useState, useRef, useCallback } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { addScore } from "../store/modules/players/actions";
import { Player as IPlayer } from "../store/modules/players/types";

type Props = {
  player?: IPlayer;
  placeholder?: boolean;
};

const Player: React.FC<Props> = ({ player, placeholder = false }) => {
  const [inputState, setInput] = useState<string>("");
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();

  if (placeholder || !player) {
    return <Placeholder />;
  }

  const total = player.scores.reduce((acc, score) => acc + score, 0);

  const submitPoints = (e: React.FormEvent) => {
    e.preventDefault();
    dispatch(addScore(player.name, parseInt(inputState, 10) || 0));
    const inputs: HTMLInputElement[] = Array.from(
      document.querySelectorAll("input[data-tag='player-input']")
    );

    const currentIndex: number = inputs.indexOf(
      inputRef.current as HTMLInputElement
    );
    const nextIndex = currentIndex + 1 === inputs.length ? 0 : currentIndex + 1;

    inputs[nextIndex].focus();
    setInput("");
  };

  return (
    <Container
      onSubmit={submitPoints}
      onClick={() => inputRef && inputRef.current && inputRef.current.focus()}
    >
      <Name>{player.name}</Name>
      <ScoreInput
        ref={inputRef}
        data-tag="player-input"
        inputMode="numeric"
        placeholder="＋"
        size={3}
        value={inputState}
        onChange={e => setInput(e.target.value)}
        onBlur={e => setInput("")}
      />
      <Total role="button">{total}</Total>
    </Container>
  );
};

const Container = styled.form`
  background: ${props => props.theme.colors.buttonColor};
  margin: 8px;
  padding: 4px;
  display: grid;
  flex: 1 0 120px;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto;
  position: relative;
  border-radius: 2px;
  &:hover {
    cursor: pointer;
  }
`;

const Placeholder = styled(Container)`
  visibility: hidden;
`;

const Name = styled.h3`
  margin: 0;
  padding: 0;
  grid-column-start: 1;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: 1;
  text-align: left;
  padding-left: 4px;
  text-shadow: 1px 1px 1px #111;
`;

const ScoreInput = styled.input`
  grid-column-start: 1;
  grid-column-end: 2;
  grid-row-start: 2;
  grid-row-end: span 2;

  transition: outline 800ms ease-in-out, background 800ms ease-in-out;
  background: transparent;
  color: white;
  text-align: left;
  margin: 0;
  border: 0;

  &::placeholder {
    color: white;
    opacity: 0.4;
  }

  &:hover {
    cursor: pointer;
  }

  &:focus {
    transition: outline 400ms ease-in-out, background 200ms ease-in-out;
    background: rgba(0, 0, 0, 0.5);
    outline: none;

    &::placeholder {
      color: transparent;
    }
  }
`;

const Total = styled.div`
  grid-column-start: 2;
  grid-column-end: 3;
  grid-row-start: 1;
  grid-row-end: span 3;
  align-self: center;
  font-size: 24px;
  text-align: right;
  text-shadow: 1px 1px 1px #111;
  padding-right: 8px;
  font-weight: bold;
`;

export default Player;
