import React from "react";
import styled, { ThemeProvider } from "styled-components";
import { Provider } from "react-redux";
import { store } from "./store";
import WordChecker from "./components/WordChecker";
import Players from "./components/Players";
import { Color } from "csstype";

interface Theme {
  colors: {
    buttonColor: Color;
  };
}
const theme: Theme = {
  colors: {
    buttonColor: "#7b0d59"
  }
};

const App: React.FC<{}> = () => {
  return (
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <Container>
          <Header>
            <Title>Scoreboard</Title>
            <Players />
            <WordChecker />
          </Header>
        </Container>
        <Footer>
          <p>&copy;2019 Iolo Pearse. All Rights Reserved.</p>
        </Footer>
      </Provider>
    </ThemeProvider>
  );
};

const Title = styled.h2`
  font-family: "Hepta Slab";
  font-weight: bold;
  text-shadow: 1px 1px 1px #111;
`;

const Container = styled.div`
  text-align: center;
  background-color: #282c34;
  color: white;
  min-height: 100vh;
`;

const Header = styled.header`
  min-height: 50vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
`;

const Footer = styled.footer`
  font-family: "Hepta Slab";
  padding: 4px;
  text-align: center;
  font-size: 14px;
`;

export default App;
